export const dateFormatConstants = [
  { name: "YYYY-MM-DD", value: "YYYY-MM-DD" },
  { name: "MM-DD-YYYY", value: "MM-DD-YYYY" },
  { name: "DD/MM/YYYY", value: "DD/MM/YYYY" },
  { name: "DD-MM-YYYY", value: "DD-MM-YYYY" },
  { name: "MMM DD, YYYY", value: "MMM DD, YYYY" },
  { name: "MMMM DD, YYYY", value: "MMMM DD, YYYY" },
  { name: "DD MMMM YYYY", value: "DD MMMM YYYY" },
  { name: "DD MMM YYYY", value: "DD MMM YYYY" },
  { name: "YYYY/MM/DD", value: "YYYY/MM/DD" },
  { name: "MM/DD/YYYY", value: "MM/DD/YYYY" }
];
